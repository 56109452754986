var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"quotes__search__form"},[_c('GlobalEvents',{on:{"keydown":function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==83){ return null; }if(!$event.altKey){ return null; }return _vm.keymap.apply(null, arguments)}}}),_c('div',{staticClass:"column px-0 py-0 is-flex is-justify-content-space-between"},[_c('div',[_c('h2',{staticClass:"is-h2"},[_c('strong',[_vm._v(_vm._s(_vm.formTitle))])]),_c('small',{staticClass:"small"},[_vm._v("Reservations")])]),_c('div',{staticClass:"quotes_search_form__toggle"},[_c('b-button',{on:{"click":_vm.setQuotesSearchFormClass}},[_c('b-icon',{staticClass:"color-8 mt-1",attrs:{"size":"is-small","custom-size":"mdi-24px","icon":"filter"}})],1)],1)]),_c('div',{class:("searchFormQuote " + _vm.quotesSearchFormClass)},[_c('b-field',{staticClass:"header_select",staticStyle:{"width":"100%"},attrs:{"label":"Search"}},[_c('Multiselect',{attrs:{"options":[],"multiple":true,"taggable":true,"tag-position":"top","max-height":200,"close-on-select":true,"clear-on-select":false,"preserve-search":true,"allow-empty":true,"placeholder":"Add a name or code","disabled":_vm.form.title === 0,"id":"searchInput","data-test-id":_vm.constants.DATA_TEST_ID_VALUES.QUOTES_SEARCH_FORM_SEARCH},on:{"tag":_vm.addSearchItem,"input":_vm.updateUrlWithForm},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),(!_vm.hiddenControls.includes(_vm.constants.QUOTES_FILTERS.CLIENT))?_c('BaseSelect',{staticClass:"field",attrs:{"label":"Client","list":[{ id: '', name: 'All' } ].concat( _vm.clients),"optionValue":"id","optionText":"name","placeholder":"Select a client","disabled":_vm.loading || _vm.localLoading,"value":_vm.form.client},on:{"input":function($event){_vm.form.client = $event;
        _vm.updateUrlWithForm();
        _vm.focusSearchButton();}}}):_vm._e(),(!_vm.hiddenControls.includes(_vm.constants.QUOTES_FILTERS.COUNTRY))?_c('b-field',{attrs:{"label":"Country"}},[_c('multiselect',{attrs:{"options":[
          { id: '', location_name: 'All', location_code: 'All' } ].concat( _vm.info.countries ),"multiple":true,"tag-position":"top","max-height":100,"close-on-select":true,"clear-on-select":false,"preserve-search":true,"allow-empty":true,"placeholder":"Select a country","track-by":"location_name","label":"location_name","disabled":_vm.loading || _vm.info.countries.length === 0},on:{"input":function (event) {
            _vm.listDestinationsByCountry(event);
            _vm.updateUrlWithForm();
            _vm.focusSearchButton();
          }},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}})],1):_vm._e(),_c('b-field',{attrs:{"label":"Destination"}},[_c('multiselect',{attrs:{"options":[
          { id: '', location_name: 'All', location_code: 'All' } ].concat( _vm.destinationsBySelectedCountries ),"multiple":true,"tag-position":"top","max-height":100,"close-on-select":true,"clear-on-select":false,"preserve-search":true,"allow-empty":true,"placeholder":"Select a destination","track-by":"location_name","label":"location_name","disabled":_vm.loading ||
          _vm.destinationsBySelectedCountries.length === 0 ||
          _vm.form.country === ''},on:{"input":function($event){_vm.form.location = $event;
          _vm.updateUrlWithForm();
          _vm.focusSearchButton();}},model:{value:(_vm.form.location),callback:function ($$v) {_vm.$set(_vm.form, "location", $$v)},expression:"form.location"}})],1),_c('b-field',{staticClass:"range-date",attrs:{"label":"Range Date"}},[(!_vm.hiddenControls.includes(_vm.constants.QUOTES_FILTERS.FROM))?_c('BaseDatePicker',{staticClass:"field",attrs:{"placeholder":"From","size":"medium"},model:{value:(_vm.form.from),callback:function ($$v) {_vm.$set(_vm.form, "from", $$v)},expression:"form.from"}}):_vm._e(),(!_vm.hiddenControls.includes(_vm.constants.QUOTES_FILTERS.TO))?_c('BaseDatePicker',{staticClass:"date_to field",attrs:{"placeholder":"To","size":"medium"},model:{value:(_vm.form.to),callback:function ($$v) {_vm.$set(_vm.form, "to", $$v)},expression:"form.to"}}):_vm._e()],1),(!_vm.hiddenControls.includes(_vm.constants.QUOTES_FILTERS.CREATION))?_c('BaseDatePicker',{staticClass:"field",attrs:{"label":"Creation","placeholder":"Creation"},model:{value:(_vm.form.creation),callback:function ($$v) {_vm.$set(_vm.form, "creation", $$v)},expression:"form.creation"}}):_vm._e(),(
        !_vm.hiddenControls.includes(
          _vm.constants.QUOTES_FILTERS.ITINERARY_TYPE_ID
        ) && _vm.displayItineraryTypes
      )?_c('BaseSelect',{staticClass:"field",attrs:{"label":"Type","list":_vm.itineraryTypesOptions,"optionValue":"id","optionText":"name","placeholder":"Select a type","disabled":_vm.loading ||
        _vm.disabledControls.includes(_vm.constants.QUOTES_FILTERS.ITINERARY_TYPE_ID),"value":_vm.form.itinerary_type_id,"data-test-id":_vm.constants.DATA_TEST_ID_VALUES.QUOTES_SEARCH_FORM_TYPE},on:{"input":function($event){_vm.form.itinerary_type_id = $event;
        _vm.updateForm(Object.assign({}, _vm.form, {itinerary_type_id: $event}));
        _vm.updateUrlWithForm();
        _vm.focusSearchButton();}}}):_vm._e(),(!_vm.hiddenControls.includes(_vm.constants.QUOTES_FILTERS.STATUS))?_c('BaseSelect',{staticClass:"field",attrs:{"label":"Status","list":[{ id: '', name: 'All' } ].concat( _vm.status_itinerary),"optionValue":"id","optionText":"name","placeholder":"Select a status","disabled":_vm.loading,"value":_vm.form.status},on:{"input":function($event){_vm.form.status = $event;
        _vm.updateUrlWithForm();
        _vm.focusSearchButton();}}}):_vm._e(),_c('b-field',{attrs:{"label":"Tags"}},[_c('multiselect',{attrs:{"options":_vm.info.tags,"multiple":true,"tag-position":"top","max-height":100,"close-on-select":true,"clear-on-select":false,"preserve-search":true,"allow-empty":true,"placeholder":"Select a tags","track-by":"name","label":"name","disabled":_vm.loading || _vm.info.tags.length === 0,"data-test-id":_vm.constants.DATA_TEST_ID_VALUES.QUOTES_SEARCH_FORM_TAGS},on:{"input":function () {
            _vm.updateUrlWithForm();
            _vm.focusSearchButton();
          }},model:{value:(_vm.form.tags),callback:function ($$v) {_vm.$set(_vm.form, "tags", $$v)},expression:"form.tags"}})],1),_c('div',{staticClass:"buttons-form-search"},[_c('BaseButton',{ref:"searchQuotesButton",attrs:{"size":"large","action":"principal","data-test-id":_vm.constants.DATA_TEST_ID_VALUES.QUOTES_SEARCH_FORM_SEARCH_BUTTON},on:{"click":function($event){return _vm.handleSearchItineraries(_vm.form)}}},[_c('IconSearch',{staticClass:"icon is-small",attrs:{"slot":"icon"},slot:"icon"}),_c('p',{attrs:{"slot":"text"},slot:"text"},[_vm._v("Search")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }